<template>
  <div>
    <router-link to="/posts">
      <div class="f-ac hoverable" style="display: inline-flex">
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.25472 1.5L1 8M7.25472 14.5L1 8M1 8H16.5" stroke="#2D3142" stroke-width="1.51163" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="text--dark text-bold" style="margin-left: 10px">Tillbaka</p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>