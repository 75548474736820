<template>
  <div class="post-wrapper">
    <sidebar />
    <article>
      <back-button style="margin-bottom: 20px"/>
      <el-carousel class="carousel" :interval="5000" arrow="always" height="500px">
        <el-carousel-item v-for="url in post.images" :key="url">
          <img class="carousel-img" :src="url" />
        </el-carousel-item>
      </el-carousel>

      <p class="text--dark">{{ post.dateString }}</p>
      <h1 class="text--dark">{{ post.title }}</h1>

      <div class="post-content text--dark" v-html="post.inner">
      </div>
    </article>
  </div>
</template>

<script>
import BackButton from '../components/BackButton.vue'
import Sidebar from '../components/Sidebar.vue'
export default {
  data() {
    return {
      title: '',
    }
  },
  metaInfo () {
    if(Object.keys(this.post).length === 0) {
      console.log('no post')
      return {
        title: 'Blomstrande Liv'
      }
    }

    return {
      title: this.post.title,
      meta: [
        { vmid: 'description', name: 'description', content: this.post.description },
        { vmid: 'og:title', property: 'og:title', content: this.post.title },
        { vmid: 'og:description', property: 'og:description', content: this.post.description },
        { vmid: 'og:image', property: 'og:image', content: this.post.images[0] },
        { vmid: 'twitter:title', name: 'twitter:title', content: this.post.title },
        { vmid: 'twitter:description', name: 'twitter:description', content: this.post.description },
        { vmid: 'twitter:image', name: 'twitter:image', content: this.post.images[0] },
        { vmid: 'og:type', property: 'og:type', content: 'article' },
        { vmid: 'og:url', property: 'og:url', content: 'https://www.blomstrandeliv.se/posts/' + this.post.slug },
      ]
    }
  },
  components: { Sidebar, BackButton },
  computed: {
    post() {
      const posts = this.$store.state.posts
      for(const i in posts) {
        if(posts[i].slug === this.$route.params.slug) {
          // this.title = posts[i].title
          return posts[i]
        }
      }

      return {}
    },
  },
  updated() {
    window.scrollTo(0, 0)
  },
  mounted () {
    window.scrollTo(0, 0)
    this.$store.commit('setArchiveQuery', { query: '', order: false })
  },
}
</script>

<style scoped>

.post-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 65px;
  /* min-height: 100vh; */
  box-sizing: border-box;
}

article {
  padding-top: 20px;
  padding-bottom: 60px;
  /* padding-left: 50px; */
  padding-right: var(--horizontal-margin);
  overflow-x: hidden;
  width: 100%;
  /* max-width: 80ch; */
}

.post-content {
  margin: 0 auto;
  margin-top: 30px;
}

.post-content >>> a {
  color: var(--c-primary);
  text-decoration: underline;
}

.post-content >>> img {
  max-width: none;
  width: 100%;
  height: auto;
}

.carousel {
  margin-bottom: 20px;
}

.carousel-img {
  max-width: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

@media screen and (max-width: 1200px) {
  article {
    padding-left: var(--horizontal-margin)
  }
}

</style>