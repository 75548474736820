<template>
  <div class="sidebar-container">
    <!-- <div class="sidebar-hide" :class="{'sidebar-extended': extended}" @click="extended = !extended"></div> -->
    <simplebar class="sidebar shadow" :class="{'sidebar-extended': extended}">
      <!-- <input @keyup="updateQuery" v-model="searchQuery" type="text" placeholder="Sök"> -->
      <div style="position: relative;">
        <svg class="search-icon" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M2.83419 9.08905C1.03369 6.90926 1.14941 3.66853 3.18135 1.62511C5.336 -0.541705 8.82937 -0.541705 10.984 1.62511C13.1387 3.79193 13.1387 7.30504 10.984 9.47186C8.97201 11.4952 5.79268 11.6292 3.62611 9.87372L0.948901 12.5661C0.731827 12.7844 0.37988 12.7844 0.162806 12.5661C-0.0542685 12.3478 -0.0542687 11.9938 0.162806 11.7755L2.83419 9.08905ZM3.47721 8.4424L4.2703 9.2259C6.07925 10.6287 8.68603 10.4962 10.3443 8.82855C12.1457 7.01702 12.1457 4.07995 10.3443 2.26842C8.54297 0.456891 5.6224 0.456891 3.82104 2.26842C2.1427 3.95624 2.02809 6.62113 3.47721 8.4424Z"/>
        </svg>
        <input @keyup="updateQuery" v-model="searchQuery" type="text" placeholder="Sök" class="archive-search-input">
      </div>

      <div class="f-r f-ac hoverable" style="display: inline-flex; margin: 20px 0" @click="toggleSorting()">
        <p class="text--dark text-bold unselectable" style="margin-right: 10px">Datum</p>
        <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{'invert-arrow': sorting}">
          <path d="M4.13397 1C4.51887 0.333333 5.48113 0.333334 5.86603 1L9.33013 7C9.71503 7.66667 9.2339 8.5 8.4641 8.5H1.5359C0.766098 8.5 0.284973 7.66667 0.669873 7L4.13397 1Z" fill="#2D3142"/>
        </svg>
      </div>

      <div v-for="post in postsToDisplay" :key="post.slug">
        <div class="f-r f-ac">
          <post-list-entry :post="post" :admin="false" />
        </div>
        <!-- <div class="divider"></div> -->
      </div>

      <el-pagination
        style="margin: 50px auto; width: min-content"
        background
        hide-on-single-page
        layout="prev, pager, next"
        :current-page.sync="page"
        :page-size="10"
        :pager-count="5"
        :total="8">
      </el-pagination>
    </simplebar>
    <!-- <div class="sidebar-padding"></div> -->
  </div>
</template>

<script>
import PostListEntry from './PostListEntry.vue'
import simplebar from 'simplebar-vue'
import 'simplebar-core/dist/simplebar.css'

import { debounce } from '../store/debouncer'

export default {
  components: { PostListEntry, simplebar },
  data() {
    return {
      sorting: false,
      extended: false,
      searchQuery: '',
      page: 0,
    }
  },
  methods: {
    toggleSorting() {
      this.sorting = !this.sorting
      this.$store.commit('setArchiveQuery', { query: this.searchQuery, order: this.sorting })
    },
    updateQuery() {
      const that = this
      debounce(function () {
        that.$store.commit('setArchiveQuery', { query: that.searchQuery, order: that.sorting })
      }, 500)()
    }
  },
  computed: {
    postsToDisplay() {
      const all = this.$store.state.queryResults
      return all.slice((this.page - 1) * 10, (this.page - 1) * 10 + 10)
    },
  },
}
</script>

<style scoped>

.sidebar {
  position: sticky;
  top: 65px;
  height: calc(100vh - 65px);
  width: var(--sidebar-width);
  background-color: var(--c-background);
  padding-left: var(--horizontal-margin);
  padding-right: 20px;
  padding-top: 20px;
  margin-right: 50px;
  /* padding-bottom: 300px; */
  box-sizing: border-box;

  /* border-right: var(--c-primary) 2px solid; */

  /* overflow-y: scroll; */
}


.sidebar-padding {
  height: 100%;
  width: var(--sidebar-width);
}

.invert-arrow {
  transform: rotate(180deg);
}

.sidebar-hide {
  z-index: 999;
  position: fixed;
  background-color: var(--c-background);
  width: 40px;
  height: 40px;
  top: 85px;
  left: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: var(--c-primary) 2px solid;
  border-right: var(--c-primary) 2px solid;
  border-bottom: var(--c-primary) 2px solid;

  transition: left 500ms ease;
}

.sidebar-hide.sidebar-extended {
  left: calc(var(--sidebar-width) - 2px);
}

.search-icon {
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  fill: var(--c-text-dark);
  content: 'dw';
}

.archive-search-input {
  padding-left: 30px;
}

@media screen and (max-width: 1200px) {
  .sidebar-container {
    display: none;
  }
}

</style>