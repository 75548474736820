<template>
<!-- `/posts/${post.slug}` -->
  <router-link :to="admin ? {name: 'PostsWrite', params: {editModel: post} } : `/posts/${post.slug}`" style="width: 100%">
    <div class="post-list-entry f-r  hoverable">
      <div class="post-list-entry-indicator"></div>
      <img :src="post.images[0]">
      <div>
        <p class="text--dark text-small" style="margin-bottom: 5px">{{post.dateString}}</p>
        <p class="text--dark text-bold post-list-entry-title">{{post.title}}</p>
        <!-- <p class="text--dark post-list-entry-summary">{{post.plain}}</p> -->
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    post: {},
    admin: false,
  },

}
</script>

<style>

.post-list-entry {
  margin: 10px 0;
  /* box-sizing: border-box; */
}

.post-list-entry img {
  flex-shrink: 0;
  max-width: 130px;
  max-height: 90px;
  width: 130px;
  height: 90px;
  object-fit: cover;
  margin-right: 10px;
  image-rendering: -webkit-optimize-contrast;
  border-radius: 10px;
  /* box-sizing: border-box; */ 
}

.post-list-entry-summary {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.post-list-entry-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3; 
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 1500px) {
  .post-list-entry div p {
    /* font-size: 16px; */
  }
}

@media screen and (max-width: 1200px) {
  .post-list-entry img {
    display: none;
  }
}

</style>